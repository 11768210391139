import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import StripeRedirect from "../views/quizPreviewComp/StripeRedirect.vue";
import V16Header from "../views/Dashboard/Account/V16Header.vue";
import ColonBroomBMI from "../views/quizPreviewComp/specialClients/ColonBroomBMI.vue";

Vue.use(VueRouter);

function loadView(view) {
  return () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "pages/[request]" */
      `@/views/${view}.vue`
    );
}
const QuizellSupport = () =>
  import(
    /* webpackPrefetch: 0 */ /* webpackChunkName: "client-preview" */ "@/views/quizPreviewComp/specialClients/QuizellSupport/index"
  );
const PreviewFinalPage = () =>
  import(
    /* webpackPrefetch: 0 */ /* webpackChunkName: "client-preview" */ "@/views/preview/index.vue"
  );
const QuizFinal = () =>
  import(
    /* webpackPrefetch: 0 */ /* webpackChunkName: "client-preview" */ "@/views/quiz/index.vue"
  );
  

const routes = [
  {
    path: "/",
    redirect: "/manage/quizzes",
  },

  {
    path: "/customize",
    component: loadView("Dashboard/Customize"),
    name: "Customize",
    children: [
      {
        path: "/customize/create/:id",
        name: "Create",
        component: loadView("customize/index"),
        meta: { title: "Design - Quizell", loadCrisp: false },
      },
      {
        path: "/customize/configuration/:id",
        name: "Connect",
        component: loadView("customize/connect/index"),
        meta: { title: "Configuration - Quizell", loadCrisp: false },
      },
      {
        path: "/customize/share/:id",
        name: "Share",
        component: loadView("customize/share/index"),
        meta: { title: "Share - Quizell" },
      },
    ],
  },
  {
    path: "/manage",
    redirect: "/manage/quizzes",
    component: loadView("Dashboard/index"),
    name: "Dashboard",
    children: [
      {
        path: "/manage/quizzes",
        component: loadView("Dashboard/quizzes"),
        meta: { title: "Home - Quizell" },

        beforeEnter: (to, from, next) => {
          guard(to, from, next);
        },
        name: "ManageQuiz",
      },
      {
        path: "analytics",
        name: "Analytics",
        component: loadView("Dashboard/analyse/index"),
        meta: { title: "Analyse - Quizell" },
      },
     
      {
        path: "/manage/account",
        // template: "<router-view></router-view>",
        component: V16Header,
      
        meta: { title: "My Account - Quizell" },
        children: [
          {
            path: "/manage/emails/verified-sender-details/create",
            component: loadView("Dashboard/Customers/SendEmailVerification/SenderEmailForm"),
            props:{senderFormMode:'create'},
            name: "CreateSenderEmailVerification",
            meta: { title: "Verified Sender Details- Quizell" },
          },
          {
            path: "/manage/emails/verified-sender-details/update/:id",
            component: loadView("Dashboard/Customers/SendEmailVerification/SenderEmailForm"),
            props:{senderFormMode:'update'},
            name: "UpdateSenderEmailVerification",
            meta: { title: "Verified Sender Details- Quizell" },
          },
          {
            path: "/manage/emails/verified-sender-details",
            component: loadView("Dashboard/Customers/SendEmailVerification/index"),
            name: "SenderEmailVerification",
            meta: { title: "Verified Sender Details- Quizell" },
          },
          {
            path: "/manage/account/user_profile",
            component: loadView("Dashboard/Account/account"),
            name: "UserProfile",
            meta: { title: "User Profile - Quizell" },
          },
          {
            path: "/manage/account/access_tokens",
            component: loadView("Dashboard/Account/AccessToken"),
            name: "AccessToken",
            meta: { title: "Access Tokens - Quizell" },
          },
          {
            path: "roles-permissions",
            name: "RolesAndPermission",
            component: loadView("Dashboard/RolesAndPermission/index"),
            beforeEnter: (to, from, next) => {
              checkInviteParent(to, from, next);
            },
            meta: { title: "Role and Permissions - Quizell" },
          },
          {
            path: "/manage/account/invoice",
            component: loadView("Dashboard/Account/InvoiceComp"),
            name: "Invoice",
            meta: { title: "Invoice - Quizell" },
          },
        ]
      },

      {
        path: "/manage/webhooks",
        // template: "<router-view></router-view>",
        component: loadView("Dashboard/Account/index"),
        redirect: "/manage/account/user_profile",
        meta: { title: "My Account - Quizell" },
        children: [
        
          {
            path: "/manage/webhook",
            component: loadView("Dashboard/Account/WebhookList"),
            name: "Webhook",
            meta: { title: "Webhook - Quizell" },
          },
          {
            path: "/manage/webhook-simulator",
            component: loadView("Dashboard/Account/WebhookList"),
            name: "webhook-secret",
            meta: { title: "Webhook Secret - Quizell" },
          },
          {
            path: "/manage/webhook-log",
            component: loadView("Dashboard/Account/WebhookList"),
            name: "webhook-log",
            meta: { title: "Webhook Log - Quizell" },
          },
          {
            path: "/manage/account/pricing",
            component: loadView("Dashboard/Account/PricingComp"),
            name: "Pricing",
            beforeEnter: (to, from, next) => {
              guard(to, from, next);
            },
            meta: { title: "Pricing - Quizell" },
          },
          
        ],
      },

      // Manage Customers
      {
        path: "/manage/customers",
        component: loadView("Dashboard/Customers/Leads"),
        name: "Leads",
        meta: { title: "Customer Leads - Quizell" },
      },

      {
        path: "/manage/products",
        name: "Product",
        component: loadView("Dashboard/product/index"),
        meta: { title: "Products - Quizell" },
      },
      {
        path: "/manage/products/collections",
        name: "Collection",
        component: loadView("Dashboard/product/Collections"),
        meta: { title: "Collections - Quizell" },
      },
      {
        path: "/manage/products/tags",
        name: "Tag",
        component: loadView("Dashboard/product/Tags"),
        meta: { title: "Tags - Quizell" },
      },
      {
        path: "/manage/products/bullet-description",
        name: "BulletDescription",
        component: loadView("Dashboard/product/BulletDescription"),
        meta: { title: "Bullet Description - Quizell" },
      },
      {
        path: "/manage/products/vendors",
        name: "Vendor",
        component: loadView("Dashboard/product/Vendors"),
        meta: { title: "Vendor - Quizell" },
      },
      {
        path: "/products/:productId/edit",
        name: "EditProduct",
        component: loadView("Dashboard/product/EditProduct"),
      },
      {
        path: "/products/create",
        name: "AddProduct",
        component: loadView("Dashboard/product/AddProduct"),
      },

      {
        path: "/integrations",
        component: loadView("Integrations/index"),
        name: "Integrations",
        redirect: "/integrations/list",
        meta: { title: "Intergations - Quizell" },
        children: [
          {
            path: "/integrations/list",
            name: "AllIntegrations",
            component: loadView("Integrations/AllIntegrations"),
            meta: { title: "Intergations - Quizell" },
          },
          {
            path: "/integrations/:id",
            name: "DetailIntegration",
            component: loadView("Integrations/DetailIntegration"),
            meta: { title: "Intergations - Quizell" },
          },
          {
            path: "/integrations/redirect/:integration",
            name: "IntegrationRedirect",
            component: loadView("Integrations/IntegrationRedirect"),
            meta: { title: "Connect Integration - Quizell" },
          },
        ],
      },

      // Email Module Routes Start
      {
        path: "/manage/emails/segments/leads",
        component: loadView("Dashboard/Customers/Segments/SegmentsLeads/Leads"),
        name: "SegmentsLeads",
        meta: { title: "Segment Leads- Quizell" },
      },
      {
        path: "/manage/emails/",
        component: V16Header,
        props: { title: 'Email Flow',
          links:[{
            name:'Flows',
            routerName:'FlowList'
            },
            {
                name:'Segments',
                routerName:'Segments'
            },
            {
                name:'Templates',
                routerName:'Email'
            },
           
        ]
         },
        name: "EmailFlow",
        children:[
      
      {
        path: "/manage/emails/segments",
        component: loadView("Dashboard/Customers/Segments/index"),
        name: "Segments",
        meta: { title: "Segments- Quizell" },
      },
      {
        path: "/manage/emails/segments/update/:id",
        props:true,
        component: loadView("Dashboard/Customers/Segments/UpdateModal"),
        name: "UpdateSegments",
        meta: { title: "Segments- Quizell" },
      },
      {
        path: "/manage/emails/segments/create",
        component: loadView("Dashboard/Customers/Segments/CreateModal"),
        name: "CreateSegments",
        meta: { title: "Segments- Quizell" },
      },
      {
        path: "/manage/emails/templates",
        component: loadView("Dashboard/Customers/Email/index"),
        name: "Email",
        meta: { title: "Emails- Quizell" },
      },
      {
        path: "/manage/emails/flows/list",
        component: loadView("Dashboard/Customers/Flows/List"),
        name: "FlowList",
        meta: { title: "Flows- Quizell" },
      },
    ]}

      // Email Module Routes Ends
    ],
  },
  // component: V16Header,
  
  {
    path: "/manage/emails/create-email-templates",
    component: loadView("Dashboard/Customers/Email/CreateEmailTemplate/index"),
    name: "CreateEmailTemplates",
    props: true,
    meta: { title: "Create Email Templates- Quizell" },
  },
  {
    path: "/manage/emails/flows/:id",
    component: loadView("Dashboard/Customers/Flows/CreateFlow"),
    name: "Flows",
    props: true,
    meta: { title: "Flows- Quizell" },
  },
  {
    path: "/manage/emails/flows/:id/email-content/:contentId",
    component: loadView("Dashboard/Customers/Flows/FlowEmailContent"),
    name: "FlowEmailContent",
    props: true,
    meta: { title: "Flows Email Content - Quizell" },
  },

  {
    path: "/EmailEditor/:id",
    component: loadView("EmailEditor/Editor"),
    name: "EmailEditor",
    props: true,
    meta: { title: "Email Editor - Quizell" },
  },
  {
    path: "/manage/create-quiz",
    name: "CreateQuiz",
    component: loadView("Dashboard/CreateQuiz/index"),
    meta: { title: "Create Quiz - Quizell" },
  },
  {
    path: "/colonbroom",
    // component: loadView("preview/index"),
    component: ColonBroomBMI,
    name: "ColonBroomBMI",
    meta: { loadCrisp: false },
  },
  {
    path: "/preview/:id",
    // component: loadView("preview/index"),
    component: PreviewFinalPage,
    name: "PreviewFinalPage",
    meta: { loadCrisp: false },
  },
  {
    path: "/contact/QuizellSupport",
    component: QuizellSupport,
    name: "QuizellContactQuiz",
    meta: { loadCrisp: false },
  },
  {
    path: "/d4w/:id",
    component: loadView("quizPreviewComp/specialClients/FreightQuottResult"),
    // component: D4wResultPage,
    props: true,
    name: "D4wResultPage",
    meta: { loadCrisp: false },
  },
  {
    path: "/test/:id",
    component: loadView("QuizTestModal/testIndex"),
    name: "QuizTestFinal",
  },
  {
    path: "/quiz/:id",
    // component: loadView("quiz/index"),
    component: QuizFinal,
    name: "QuizFinal",
    meta: { loadCrisp: false },
  },
  {
    path: "/home/:id?",

    name: "wixLogin",

    component: loadView("WixAuth/login"),
  },

  {
    path: "/wix-account-update",

    name: "wixAccountUpdate",

    component: loadView("WixAuth/wixAccountUpdate"),
  },
  {
    path: "/user-invitation/sign-up",

    component: loadView("Dashboard/RolesAndPermission/InvitationLayout"),
    // component:SignUp,
    name: "invitation-sign-up",
    meta: { title: "Sign Up - Quizell" },
  },
  {
    path: "/invitation/:id",
    component: loadView("Dashboard/RolesAndPermission/Auth"),
    name: "Invitation",
    props: true,
    meta: { title: "Invitation - Quizell" },
  },
  {
    path: "/login",
    component: loadView("SignIn/Auth"),
    name: "login",
    meta: { title: "Login - Quizell" },
  },
  {
    path: "/sign-up",
    component: loadView("SignIn/Auth"),
    // component:SignUp,
    name: "sign-up",
    meta: { title: "Sign Up - Quizell" },
  },
 
  {
    path: "/email-verify",
    component: loadView("SignIn/EmailVerify"),
    // component:SignUp,
    name: "EmailVerify",
    meta: { title: "Verify Email - Quizell" },
  },
  {
    path: "/account/verify/:token",
    component: loadView("SignIn/VerfiedEmail"),
    name: "VerifiedEmail",
    meta: { title: "Account Verify - Quizell" },
  },
  {
    path: "/password-reset",
    component: loadView("SignIn/PasswordReset"),
    name: "ResetPassword",
    meta: { title: "Reset Password - Quizell" },
  },
  {
    path: "/password-reset/:id",
    component: loadView("SignIn/VerifyPasswordReset"),
    name: "VerifyResetPassword",
    meta: { title: "Reset Password - Quizell" },
  },
  {
    path: "/authenticate",
    component: loadView("ShopifyAUTH/index"),
    name: "Shopify Login",
    meta: { title: "Shopify Login - Quizell" },
  },
  {
    path: "/authenticate/shopify",
    component: loadView("ShopifyAUTH/shopifyAuthenticate"),
    name: "Shopify Authenticate",
    meta: { title: "Shopify Authenticate - Quizell" },
  },
  {
    path: "/authenticate/shopify/editor",
    component: loadView("ShopifyAUTH/shopifyQuizAuthenticate"),
    name: "Shopify Authenticate Editor",
    meta: { title: "Shopify Editor - Quizell" },
  },

  {
    path: "/bigcommerce/auth/install",
    component: loadView("BCInstall/index"),
    name: "Big Commerce Install",
    meta: { title: "Big Commerce Install - Quizell" },
  },
  {
    path: "/bigcommerce/auth/load",
    component: loadView("BCLoad/index"),
    name: "Big Commerce Login",
    meta: { title: "Big Commerce Login - Quizell" },
  },
  {
    path: "/select-shopify-plan",
    component: loadView("ShopifyPlan/index"),
    name: "ShopifyPlan",
    meta: { title: "Shopify Plan - Quizell" },
  },
  {
    path: "/payment/callback",
    component: loadView("PaypalPaymentCallback/index"),
    name: "PaymentPaypalCallback",
    meta: { title: "Payment - Quizell" },
  },
  {
    path: "/payment/successfull",
    component: loadView("Dashboard/Account/PaymentCelebration"),
    name: "PaymentSuccessfull",
    meta: { title: "Payment Successfull - Quizell" },
  },
  {
    path: "/shopify-plans",
    component: loadView("ShopifyPlan/index"),
    name: "Shopify Plans",
    meta: { title: "Shopify Plans - Quizell" },
  },
  // Error404
  {
    path: "*",
    name: "404",
    component: loadView("404/index"),
  },

  //Stripe Redirect
  {
    path: "/stripe_redirect",
    // component:loadView('views/preview/StripeRedirect'),
    component: StripeRedirect,
    name: "StripeRedirect",
    meta: { title: "Redirect-Quizell" },
  },
];
const checkInviteParent = function (to, from, next) {
  const isWorkSpaceUser = localStorage.getItem("isWorkSpaceUser");

  next(isWorkSpaceUser == "true" ? "/" : undefined);
};

const guard = async function (to, from, next) {
  try {
    const response = await axios.get("/getCurrentUser");

    if (response.status === 200) {
      const userData = response.data.data;

      // if (userData.email === process.env.VUE_APP_INVITED_USER_PARENT_EMAIL) {
      //   localStorage.setItem('isInvitedUserParent', 'true');
      // }

      if (userData.isShopifyUser && userData.planId === null) {
        next("/select-shopify-plan");
      } else {
        next();
      }
    } else {
      handleRedirection();
    }
  } catch (error) {
    console.log("Error Response", error.response);
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 498)
    ) {
      handleRedirection();
    }
  }
};

function handleRedirection() {
  localStorage.removeItem("user_access_token");
  const URL = window.location.origin + `/login`;
  window.location.href = URL; // This is a more standard way to redirect
}

const router = new VueRouter({
  routes,
  mode: "history",
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "Quizell";
  }

  next();
});

export default router;
